<!-- 申请售后 -->
<template>
  <div id="service_reason">
    <div class="service_pro" v-if="product_list && type == 0">
      <template v-for="item in product_list" v-if="">
        <productList
          class="p32"
          :product_list="item"
          :showDom="true"
        ></productList>
      </template>
    </div>

    <div class="service_pro" v-if="product_list && type != 0">
      <productList
        class="p32"
        :product_list="product_list"
        :showDom="true"
      ></productList>
    </div>

    <div class="reason_input">
      <div class="reason_list flex-container">
        <span class="flex1" v-if="type != 0">申请原因</span>
        <b class="c" @click="showPicker = true" v-if="type != 0">{{
          thisColumns
        }}</b>
      </div>
      <div class="service_textarea">
        <textarea
          placeholder="请描述申请售后服务的具体原因"
          class="bg"
          v-model="service_text"
          :maxlength="maxTextLen"
        ></textarea>
        <span>{{ service_text.length }}/150</span>
      </div>

      <!-- <div class="service_uploadImg flex-container" >
        <div class="service_uploadImg_list" v-if='type != 0 '>
            <div class="show">
              <img src="@dajiao/order/serice_upload.png" class='upload_img'>
              <span>上传凭证</span>
            </div>
        </div>
      </div> -->
    </div>

    <div class="reason_input" v-if="type != 0 && product_list">
      <div class="reason_list flex-container">
        <span class="flex1">退款金额</span>
        <input
          type="text"
          name=""
          v-model="service_price"
          :placeholder="`最多可退${product_list.goods_pay_price}元`"
          maxlength="11"
        />
      </div>
      <div class="reason_list flex-container" v-if="type == 2">
        <span class="flex1">退货数量</span>
        <input
          type="text"
          name=""
          v-model="service_num"
          :placeholder="`最多可退${product_list.goods_num}件`"
          maxlength="11"
        />
      </div>
    </div>

    <div class="fixed_btm">
      <div class="btn" @click="goService">申请售后</div>
    </div>

    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        value-key="reason_info"
        @cancel="showPicker = false"
        @confirm="onConfirm"
        :loading="loading"
      />
    </van-popup>
  </div>
</template>
<script>
import { Popup, Toast, Picker, Dialog } from "vant";
import productList from "../list/productList";
import { post, get } from "@get/http";
import axios from "axios";
export default {
  porps: {},
  name: "",
  data: () => ({
    showPicker: false, //是否展示选择原因
    columns: [], //原因列表
    thisColumns: "请选择原因",
    product_list: false, //产品列表
    service_text: "", //申请具体原因
    maxTextLen: 150, //最多可输入
    service_price: "", //退款金额
    service_num: "", //退款货
    type: 0, //退款0  //退货的退款1  //退货other
    loading: true,
    reason_id: 0,
    submit_token: "",
    putBoo: true, //锁
    FSform: {},
  }),
  created() {
    Toast.loading({
      message: "加载中...",
      loadingType: "spinner",
      overlay: true,
      forbidClick: true,
      duration: 0,
    });

    let query = this.$route.query;
    if (query?.type) {
      //退货
      this.type = query?.type;
      this.getOrderInfoType(query?.order_id, query?.order_goods_id);
    } else {
      // 退款
      this.getOrderInfo();
    }
    this.getToken();
    Toast.clear();
  },
  watch: {},
  components: {
    productList,
    VanPopup: Popup,
    vanPicker: Picker,
  },
  methods: {
    async goService() {
      // 申请

      let goodsData = this.product_list;
      if (this.service_text.length == 0) {
        Toast("十九匠商城通知:请输入退款/退货原因");
        return false;
      }

      if (this.type != 0) {
        // 退货
        if (this.thisColumns == "请选择原因") {
          Toast("十九匠商城通知:请选择原因");
          return false;
        }
        // console.log(Number(this.service_price),Number(goodsData.goods_pay_price));
        if (
          this.service_price == 0 ||
          this.service_price.length == 0 ||
          Number(this.service_price) > Number(goodsData.goods_pay_price)
        ) {
          Toast("十九匠商城通知:请输入正确的退款金额");
          return false;
        }

        if (this.type == 2) {
          if (
            this.service_num.length == 0 ||
            this.service_num > goodsData.goods_num ||
            this.service_num == 0
          ) {
            Toast("十九匠商城通知:请输入正确的退货数量");
            return false;
          }
        }
        if (this.type == 3) {
          this.returnGoods("/v2.Memberfund/refund_post");
        } else {
          this.returnGoods();
        }
      } else {
        // 申请退款
        this.returnPrice();
      }
    },
    async getToken() {
      let { submit_token } = await post("/Index/getSubmitToken");
      this.submit_token = submit_token;
    },
    async getOrderInfoType(order_id, order_goods_id) {
      // 退货信息
      let result = await post("/v2.Memberfund/refund_form", {
        data: {
          order_id,
          order_goods_id,
        },
      });
      this.FSform = result;
      this.product_list = result.goods;
      this.columns = result.reason_list;
      this.loading = false;
    },
    async getOrderInfo() {
      // 获取退款信息
      let result = await post("/Memberrefund/refund_all_form", {
        data: {
          order_id: this.$route.query.order_id,
        },
      });
      this.product_list = result.goods_list;
      this.FSform = result;
    },
    async returnPrice() {
      //退款售后
      if (!this.putBoo) {
        return false;
      }
      this.putBoo = false;
      let data = await post("/Memberrefund/refund_all_post", {
        data: {
          order_id: this.$route.query.order_id,
          buyer_message: this.service_text,
          refund_pic: ["", "", ""],
          submit_token: this.submit_token,
        },
      });

      this.SetFeishuToast();

      // if (data.code == 10000) {

      // } else {
      //   if (data.message == "参数错误") {
      //     Toast("请勿重复提交");
      //   } else {
      //     Toast(data.message);
      //   }
      //   this.putBoo = true;
      // }
    },

    async returnGoods(url = "/Memberrefund/refund_post") {
      // 退货售后
      // /Memberrefund/refund_post

      if (!this.putBoo) {
        return false;
      }
      this.putBoo = false;
      let data = await post(url, {
        data: {
          order_id: this.$route.query?.order_id,
          order_goods_id: this.$route?.query.order_goods_id,
          refund_amount: this.service_price,
          goods_num: this.service_num,
          refund_pic: ["", "", ""],
          buyer_message: this.service_text,
          refund_type: this.type,
          reason_id: this.reason_id,
          submit_token: this.submit_token,
        },
      });
      this.SetFeishuToast();
      // if (data.code == 10000) {

      // } else {
      //   if (data.message == "参数错误") {
      //     Toast("请勿重复提交");
      //   } else {
      //     Toast(data.message);
      //   }
      //   this.putBoo = true;
      // }
    },
    onConfirm(value) {
      this.thisColumns = value.reason_info;
      this.reason_id = value.reason_id;
      this.showPicker = false;
    },
    SetFeishuToast() {
      let fs = this.FSform;
      axios(
        "https://open.feishu.cn/open-apis/bot/v2/hook/efd9e995-eaec-4a33-8025-96d27c4f3f75",
        {
          method: "POST",
          params: {
            msg_type: "interactive",
            card: {
              config: {
                wide_screen_mode: true,
                enable_forward: true,
              },
              elements: [
                {
                  tag: "div",
                  text: {
                    content: `订单编号:${fs.order.order_sn} \n`,
                    tag: "lark_md",
                  },
                },
                {
                  actions: [
                    {
                      tag: "button",
                      text: {
                        content: "去处理",
                        tag: "lark_md",
                      },
                      url:
                        "https://shop.19jiang.cn/home/Sellerrefund/index.html",
                      type: "danger",
                      value: {},
                    },
                  ],
                  tag: "action",
                },
              ],
              header: {
                title: {
                  content: `您有一笔${fs.order.store_name}的售后单,请及时处理`,
                  tag: "plain_text",
                },
              },
            },
          },
        }
      );
      Dialog.alert({
        title: "十九匠商城通知",
        message: "申请售后成功",
      }).then(() => {
        this.$router.push({
          name: "OrderService",
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";

#service_reason {
  background: #ededed;

  padding-top: rem(16);
  padding-bottom: rem(200);
}

.service_pro {
  width: rem(750);
  min-height: rem(244);
  margin: 0 auto;
  background: #ffffff;
  border-radius: rem(40) rem(40) 0px 0px;
  .p32 {
    padding: rem(32);
  }
}

.reason_input {
  background: #fff;
  padding: 0 rem(32);
  margin-bottom: rem(16);
  .reason_list {
    background: url("~@image/me/icon_more.png") no-repeat center right;
    background-size: rem(12) rem(22);
    box-sizing: border-box;
    border-bottom: rem(1) solid #ebebeb;
    span,
    b {
      height: rem(130);
      line-height: rem(130);
    }
    span {
      font-size: rem(26);
      color: #808080;
    }
    .c {
      color: #b3b3b3;
      font-size: rem(29);
    }
    b,
    input {
      font-size: rem(29);
      font-weight: bold;
      color: #343434;
      margin-right: rem(28);
      border: none;
      text-align: right;
    }
    input::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #b3b3b3;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #b3b3b3;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #b3b3b3;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #b3b3b3;
    }
  }
  .service_textarea {
    width: rem(686);
    height: rem(330);
    margin: rem(48) auto rem(32);
    position: relative;
    textarea {
      width: rem(686);
      height: rem(330);
      padding: rem(26) rem(32);
      box-sizing: border-box;
      color: #343434;
      font-size: rem(26);
      border: rem(1) solid #ebebeb;
      border-radius: rem(8);
      reize: none;
    }
    .bg {
      background: url("~@image/order/address_edit.png") no-repeat rem(20)
        rem(32);
      background-size: rem(25);
      text-indent: rem(20);
    }
    textarea::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #b3b3b3;
    }
    textarea:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #b3b3b3;
    }
    textarea::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #b3b3b3;
    }
    textarea:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #b3b3b3;
    }
    span {
      position: absolute;
      bottom: rem(32);
      right: rem(32);
      font-size: rem(22);
      color: #b3b3b3;
    }
  }
}

.service_uploadImg {
  flex-wrap: wrap;
  background: #fff;
  padding: 0 rem(6);
}
.service_uploadImg_list {
  width: rem(200);
  height: rem(200);
  background: #ffffff;
  border: rem(1) dashed #e6e6e6;
  border-radius: rem(4);
  position: relative;
  margin-bottom: rem(32);
  //
  &:nth-of-type(3n-1) {
    margin: 0 rem(34);
  }

  .show {
    position: absolute;
    width: 100%;
    .upload_img {
      width: rem(68);
      height: rem(64);
      display: block;
      margin: rem(48) auto rem(16);
    }
    span {
      display: block;
      text-align: center;
    }
  }
}

.fixed_btm {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: rem(150);
  background: #ffffff;
  box-shadow: 0px rem(-4) rem(20) 0px rgba(204, 204, 204, 0.15);
  .btn {
    margin: rem(32) auto 0;
    width: rem(640);
    height: rem(88);
    // font-size: rem(32);
    font-size: rem(32);
    font-weight: bold;
    // font-family: 'pfb';
    text-align: center;
    line-height: rem(88);
    background: #ffe6e6;
    color: #ff4848;
    border-radius: rem(44);
  }
}

::v-deep .van-picker__confirm {
  color: #ff4848;
}
</style>
